import React from 'react'

import LoreSection from './loreSection'
import { HomeObjLore1,
         HomeObjLore2,
       } from './data'

const Lore = () => {
        return (
          <>
           <LoreSection { ...HomeObjLore1 } />
           <LoreSection { ...HomeObjLore2 } />
          </>
        )
      }

export default Lore