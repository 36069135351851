import styled from "styled-components"
import {Container} from "./GlobalStyle"
import { Link } from "react-router-dom";
import { FaMagento } from "react-icons/fa";
import { ExternalLink } from 'react-external-link';

export const Nav = styled.nav`
  background-color :#f0ebe9;
  height: 80px;
  display: flex;
  justify-content : center; 
  font-size: 1.2rem;
  top:0;
  position: sticky;
  z-index: 999;
 
`
export const NavbarContainer = styled(Container)`
    display: flex;
    justify-content: space-between;
    height: 80px;
    ${Container};   
`
export const NavLogo = styled(Link)`
color: black;
justify-self: flex-start;
cursor: pointer;
text-decoration: none;
font-size: 2rem;
display: flex;
align-items: center;
`
export const NavIcon = styled(FaMagento)`
margin-right: 0.5rem;
`

export const MobileIcon = styled.div`
display: none;
@media screen and (max-width: 960px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 50%);
    font-size: 1.8rem;
    cursor: pointer;
    }
`

export const Img = styled.img`
  @media (max-width: ${({ theme }) => theme.mobile}) {
    margin-bottom: 10px;
    
  }
  
  
  left: 5px;
  right: 55px;
  border-radius: 50%;
  height: 60px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.035);
  cursor: pointer;
  padding: 1px;
  margin-left: 40px;
  &:hover {
    opacity: 0.8;
    transform: scale(1.08);
    }
`

export const NavMenu = styled.ul`
display: flex;
align-items: center;
list-style: none;
text-align: center;
@media screen and (max-width: 960px) {
display:flex;
flex-direction: column;
width: 100%;
height: 90vh;
position: absolute;
top: 80px;
left:${({ click }) => (click ? "0" : "-100%")};
transition: all 0.6s ease-in-out;
background: #101522;
}
`
export const NavItem = styled.li`
height: 80px;
border-bottom: 2px solid transparent;
 &:hover{
    border-bottom: 2px solid #d86148;
 }
 @media screen and (max-width: 960px) {
     width: 100%;
 } 
 &hover{
    border: none;
 }
`

export const NavMoto = styled.li`
color: #dd7d68;
display: flex;
align-items: center;
font-size: 1.1rem;
font-weight: bold;
text-decoration: none;
padding: 2rem 1.2rem;
height: 100%;


 `


export const NavLink = styled(Link)`
color: black;
display: flex;
align-items: center;
text-decoration: none;
padding: 0.5rem 1rem;
height: 100%;

&:hover {
    transform: scale(1.05);
    transition: all 0.1s ease-in;
    color: #d86148;
  }

@media screen and (max-width: 960px) {
    text-align: center;
    color: white;
    padding: 2rem;
    width: 100%;
    display: table;

    &:hover{
        color: #d86148;
        transition: all 0.3s ease-in-out;
    }        
}
 `
export const SocialLink = styled(ExternalLink)`
color: black;
display: flex;
align-items: center;
text-decoration: none;
padding: 0.5rem 0.4rem;
height: 100%;text-decoration: none;

&:hover{
        color: #d86148;
        transition: all 0.3s ease-in-out;
    }   

@media screen and (max-width: 960px) {
    text-align: center;
    color: white;
    width: 100%;
    display: table;

    &:hover{
        color: #d86148;
        transition: all 0.3s ease-in-out;
    }        
}
`;



export const NavItemBtn = styled.li`
 @media screen and (max-width: 960px) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
 } 
`

export const NavBtnLink = styled(Link)`
display: flex;
justify-content: center;
align-items: center;
text-decoration: none;
padding: 8px 16 px ;
height: 100%;
width: 100%;
border: none;
outline: none;

`