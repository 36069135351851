import React from 'react';
import {Container} from './styles/GlobalStyle';
import {Link} from 'react-router-dom';
import {LoreSec,
       LoreRow,
       LoreColumn,
       LoreColumnH,
       TextWrapper,
       TextWrapperH,
       TopLine,
       Heading,
       Subtitle,
       SubtitleH,
       ImgWrapper,
       Img } from './styles/loreSection.styled';


const LoreSection = ({lightBG,
                      lightTopLine,
                      lightTextDesc,
                      lightText,
                      text1,
                      text2,
                      text3,
                      text4,
                      text5,
                      topLine,
                      headline,
                      imgStart,
                      img,
                      alt,
                      start
                       }) => {
  return (
     <LoreSec lightBg={lightBG}>
       <Container>
      
       
       <LoreRow imgStart={imgStart}>
       
            <LoreColumnH>
                <TextWrapperH>
                  <TopLine lightTopLine={lightTopLine}>{topLine}</TopLine>
                  <Heading lightText={lightText}>{headline}</Heading>
                  <SubtitleH lightTextDesc={lightTextDesc}>{text1}</SubtitleH>
                </TextWrapperH>
                
            </LoreColumnH>
            <ImgWrapper start={start}>
                  <Img src={img} alt="{alt}"/>
            </ImgWrapper>
        
        
            <LoreColumn>
                <TextWrapper>
                  <Subtitle lightTextDesc={lightTextDesc}>{text2}</Subtitle>
                  <Subtitle lightTextDesc={lightTextDesc}>{text3}</Subtitle>
                  <Subtitle lightTextDesc={lightTextDesc}>{text4}</Subtitle>
                  <Subtitle lightTextDesc={lightTextDesc}>{text5}</Subtitle>
                </TextWrapper>
            </LoreColumn>
        </LoreRow>


       </Container>
     </LoreSec>
  
  )
}

export default LoreSection;