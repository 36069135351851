import React from 'react';
import {Container} from './styles/GlobalStyle';
import {Link} from 'react-router-dom';
import {InfoSec,
       InfoRow,
       InfoColumn,
       TextWrapper,
       TopLine,
       Heading,
       Subtitle,
       ImgWrapper,
       EOCard,
       Img } from './styles/infoSection.styled';

import { useState } from "react";
import ReactPlayer from "react-player";

const InfoSection = ({ primary,
                      lightBG,
                      lightTopLine,
                      lightTextDesc,
                      lightText,
                      buttonLabel,
                      description,
                      description1,
                      description2,
                      description3,
                      description4,
                      description5,
                      description6,
                      topLine,
                      headline,
                      imgStart,
                      img,
                      alt,
                      start
                       }) => {
  const [videoFilePath, setVideoFilePath] = useState(null);

  const handleVideoUpload = (event) => {
   const [file] = event.target.files;
   setVideoFilePath(URL.createObjectURL(file));
            };
  return (
     <InfoSec lightBg={lightBG}>
       <Container>
        <InfoRow imgStart={imgStart}>
            <InfoColumn>
                <TextWrapper>
                  <TopLine lightTopLine={lightTopLine}>{topLine}</TopLine>
                  <Heading lightText={lightText}>{headline}</Heading>
                  <Subtitle lightTextDesc={lightTextDesc}>{description} </Subtitle>
                  <Subtitle lightTextDesc={lightTextDesc}>{description1}</Subtitle>
                  <Subtitle lightTextDesc={lightTextDesc}>{description2}</Subtitle>
                  <Subtitle lightTextDesc={lightTextDesc}>{description3}</Subtitle>
                  <Subtitle lightTextDesc={lightTextDesc}>{description4}</Subtitle>
                  <Subtitle lightTextDesc={lightTextDesc}>{description5}</Subtitle>
                  <Subtitle lightTextDesc={lightTextDesc}>{description5}</Subtitle>
          
                </TextWrapper>
            </InfoColumn>
        <InfoColumn>
          <ImgWrapper start={start}>
              <Img src={img} alt="{alt}"/>
          </ImgWrapper>
          </InfoColumn>
          
        
        </InfoRow>
       </Container>
     </InfoSec>
  
  )
}

export default InfoSection;