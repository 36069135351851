export const HomeObj1 = {
    lightBG:true,
    primary: false,
    imgStart:'',
    lightTopLine:false,
    lightTextDesc:false,
    lightText:false,
    buttonLabel:'',
    headline:'ONCHAIN BLOCK INVADERS',
    description :'• OBI is a FULL ON-CHAIN 5400 PFP 2.0 Evolvable Collection',
    description1:'• 1 OBI=up to 1024 variants of Skins and Color Palettes',
    description2:'• Total Freedom: You can customize your OBI to your liking: combine the owned Skins and Color Palettes per OBI, back and forth as you please.  ',
    description3:'• Lore driven Collection: The Light Side and The Dark Side.',
    description4:'[ Current Status: ]',
    description5:'• Each OBI NFT includes one skin and a standard light color palette. ',
    description6:'• Additionally, there are 2 color palette options for some OBI NFTs, including a dark palette that was made available through a special minting event for those owners who opted to mint it.    ',
    img:require('./../images/Home/1_3x3.png'),
    alt:'Image',
    start:''
};

export const HomeObj2 = {
    lightBG:false,
    primary: false,
    imgStart:'',
    lightTopLine:true,
    lightTextDesc:true,
    lightText:true,
    buttonLabel:'',
    headline:'Skin Mechanics',
    description: 'The contract is designed to support up to 32 new skins FULL ON-CHAIN, unlocking the Utility component, and offering you more freedom and flexibility at the same time. ', 
    description1:'The skins mechanism is specifically designed not to impact the rarity system, therefore if you have a rare token, you will get the skins with the same rarity. No new NFTs will be created.  ',
    description2:'If you decide to switch skins, owned by you OBI NFT, you can always go back to the previous ones and switch again as you please.',
    description3:'Our proposal for the OBI PFP 2.O is Evolvable offering more freedom, utility and interaction.  ',
    description4:'',
    description5:'',
    description6:'',
    topLine:'Onchain collection with:',
    img:require('./../images/Home/2_skins.png'),
    alt:'Image',
    start:''
};

export const HomeObj3 = {
    lightBG:true,
    primary: false,
    imgStart:'start',
    lightTopLine:false,
    lightTextDesc:false,
    lightText:false,
    buttonLabel:'',
    headline:'Color Palettes Mechanics',
    description:'Our Skins system is doubled by the FULL ON-CHAIN Color Palette System.',
    description1:'For each OBI you can choose up to 32 color palettes.', 
    description2:'The launch of color palettes with the related SVG Effects (shadows, glows, patterns, line styles) will be also unlocking the Utility component while offering you a great variety of choices, more interaction and freedom.',
    description3:'The first release will be the Dark Side palette and just as the skins mechanism this will not affect or dilute the rarity system. ',
    description4:'No new NFTs will be created and you will be able to swap them anytime you want as you desire. ',
    description5:'',
    description6:'',
    topLine:'Onchain collection with:',
    img:require('./../images/Home/3_color.png'),
    alt:'Image',
    start:'true'
};

export const HomeObj4 = {
    lightBG:false,
    primary: false,
    imgStart:'',
    lightTopLine:true,
    lightTextDesc:true,
    lightText:true,
    buttonLabel:'',
    headline:'Graphical Language',
    description:'The strong graphical language is reflecting the BOLD – SIMPLE – INCLUSIVE Vision, while offering a great variety of characters and elements at the same time.',
    description1:'We started from simple prime shapes that mixed together offer a distinctive graphic line. ', 
    description2:'Everything was created with YOU in mind.',
    description3:'We tought about what you would like to see, what would make you smile and last but not least, how can we actually bring forth a strong and unique visual identity that will pass the test of time. ',
    description4:'',
    description5:'',
    description6:'',
    topLine:'Why OBIs look amazing',
    img:require('./../images/Home/4_graphical language.png'),
    alt:'Image',
    start:''
};

export const HomeObj5 = {
    lightBG:true,
    primary: false,
    imgStart:'start',
    lightTopLine:false,
    lightTextDesc:false,
    lightText:false,
    buttonLabel:'',
    headline:'OBI Game',
    description:'As we reflect on our childhood memories, we cannot help but feel a deep appreciation for the magic of retro gaming ',
    description1:'Looking forward, we envision a world where Block Invaders will offer a nostalgic journey through time, enriched by the innovations of the web 3.0 world', 
    description2:'With this future possibility and with the integration of NFTs, skins, and palettes,',
    description3:'the casual game may provide a delightful and engaging experience where players can engage in friendly competition.',
    description4:'',
    description5:'',
    description6:'',
    topLine:'Block Invaders',
    img:require('./../images/Home/5_game.png'),
    alt:'Image',
    start:'true'
};

export const HomeObj6 = {
    lightBG:false,
    primary: false,
    imgStart:'',
    lightTopLine:true,
    lightTextDesc:true,
    lightText:true,
    buttonLabel:'',
    headline:'The ETH Contracts',
    description:'The mint contract has the ability to remember what skins and color palettes you have collected in time.', 
    description1:'The Skins render contract will store and generate the OBIs while also allowing us to upload future assets, directly on-chain, like new skins.',
    description2:'The Color Palettes render contract, will store current and future colors and SVG effects for your OBI that can be combined with any Skin you own.',
    description3:'Future contracts are supported by our modular design, expect new ways to render on chain, new mechanics and more optimization.',
    description4:'',
    description5:'',
    description6:'',
    topLine:'Why OBIs are a technologically advanced civilization ',
    img:require('./../images/Home/6_contract.png'),
    alt:'Image',
    start:''
};

export const HomeObj7 = {
    lightBG:true,
    primary: false,
    imgStart:'start',
    lightTopLine:false,
    lightTextDesc:false,
    lightText:false,
    buttonLabel:'',
    headline:'The Lore',
    description:'The Sun was up. Full of hope and radiant power. Its fire wasn’t burning, just shining from the inside out, like a luminous shiver of life over every living creature, covering everything in its magnificent, omnipresent power. This is The Light, just like a sunset bursting abundantly all the love and peace that every mortal desires.The Light is the primordial force that made everything possible. The force that has the power to create but never uses the force to destroy, but to maintain the balance of all things that ever were, that are, and that will ever be. Unconditional love, the power of forgiveness, detachment, selflessness, peace, joy, harmony, sacrifice for the greater good, kindness, beauty, gentleness and compassion. All things immersed in the Light will come out changed for the better, never hidden, always in plain sight, for all eyes to see the wonderful contagious energy that flows so naturally and infinitely on the entire creation. The Light is carrying and guarding the Vital Force.',
    description1:'', 
    description2:'',
    description3:'',
    description4:'',
    description5:'',
    description6:'',
    topLine:'First Chapter:The Light Side',
    img:require('./../images/Home/7_light_vs_dark.png'),
    alt:'Image',
    start:'true'
};

export const HomeObjLore1 = {
    lightBG:true,
    primary: false,
    imgStart:'',
    lightTopLine:false,
    lightTextDesc:false,
    lightText:false,
    buttonLabel:'',
    headline:'The Light Side and The Dark Side',
    text1:'The Sun was up. Full of hope and radiant power. Its fire wasn’t burning, just shining from the inside out, like a luminous shiver of life over every living creature, covering everything in its magnificent, omnipresent power. This is The Light, just like a sunset bursting abundantly all the love and peace that every mortal desires.',
    text2:'The Light is the primordial force that made everything possible. The force that has the power to create but never uses it to destroy, but to maintain the balance of all things that ever were, that are, and that will ever be. Unconditional love, the power of forgiveness, detachment, selflessness, peace, joy, harmony, sacrifice for the greater good, kindness, beauty, gentleness and compassion. All things immersed in the Light will come out changed for the better, never hidden, always in plain sight, for all eyes to see the wonderful contagious energy that flows so naturally and infinitely on the entire creation. The Light is carrying and guarding the Vital Force.',
    text3:'The Light comes in peace, but she also faces her own challenges in keeping the Greater Good in all creation and maintaining balance. Here we meet the Dark Side.',
    text4:'The story began a long, long time ago, on a far, far away Chain, that was known by the elderly as the Primordial Chain, which is the key to our entire storyline. The Light held the entire power of creation, but just as any creator, this Side had both the power to create and to destroy as they pleased. The Light was very peaceful never using the power of destruction and dim any of the beautiful light, but only to create. That, until one day, when everything was about to change. Forever. On the Primordial Chain all the forces were left freely for everybody to immerse in and recharge. These forces had the appearance of globes radiating light: the globe of love, of light, kindness, compassion and everything you could ever wish for. The only globe that was different and nobody ever desired to touch or immerse in, was the dark globe of destruction and opposition. Touching the dark globe was not forbidden as the Light always respected self-will, but everyone was obedient and happy with all the gifts already shared freely, knowing that once they touch it, everything will change. That until one day, when someone decided to immerse in it out of curiosity, and this is where our story truly begins. After one immersed deeply and dived into the dark power of opposition and destruction and came on the other side of it completely changed, many other followed.',
    text5:'That day remained in the history of the Primordial Chain as a tragic day, as what followed was beyond imagination. The Light was now dealing with a completely separate faction that was now called the Dark. And it was growing. The Light had to adapt, fight back, protect themselves and the Vital Force that keeps all things in balance',
    topLine:'-A primordial story-',
    img:require('./../images/Lore/OBI_light.PNG'),
    alt:'Image',
    start:''
};

export const HomeObjLore2 = {
    lightBG:false,
    primary: false,
    imgStart:'',
    lightTopLine:true,
    lightTextDesc:true,
    lightText:true,
    buttonLabel:'',
    headline:'',
    text1:'The Dark was the opposite of everything the Light was standing for, and everyone who went through the transformation had no recollection of how they were before. Everything they knew started with their transformation once they came out of the Dark Globe of Destruction. And that brought pain and tragedy, as the Dark became stronger and stronger. This side was fierce, operating in the shadow, plotting. Lies were at the forefront, deceit, destruction, opposition, unending battles and an ardent wish to destroy everything and everyone from the Light. The Dark Side was selfish, cynical, angry, impulsive, bitter, attached to matter, proud, ruled by hatred and the satisfaction of causing and seeing pain and destruction. Always operating in the shadow, everything dire, gloomy and confusing. Everything coming from the Light was despised and doomed. ',
    text2:'There was no room for peace, but only for war. The Dark was hunting with great maliciousness, intelligence and no mercy whatsoever. It was imitating the organization of the Light, and became very organized as well, taking every act of love, mercy and compassion and turning it into a lethal war weapon. Unending wars, battles, despair, in the confrontation between the two Sides and this story continues even as we speak. ',
    text3:'The Light created an ingenious plan for the Final Battle, only known by a trusted few, as they are on the constant threat of some of them switching sides as it happened before. They are adamant in safely carrying and guarding the Vital Force that maintains the balance of all things. For this plan they use their new Mothership which will carry them through this difficult journey on a new Chain, the ETH Chain. The Light is strong and determined to win and therefore maintain the Greater Good in all aspects of life. But do we really know them? What is at stake here? What are they willing to sacrifice for peace? Are they really peaceful? Should they be trusted?',
    text4:'Nobody really knows them. These are in our mortal eyes just factions, as we have never seen before. For us, they are just invaders, the Block Invaders. And they are coming, they are coming fast, seeking to land fully on the ETH Chain. Are your ready to embrace the unknown?',
    text5:'',
    topLine:'',
    img:require('./../images/Lore/OBI_dark.PNG'),
    alt:'Image',
    start:''
};