import React from 'react'
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css'; 
import { TimeLineContainer,TimeLineWrapper,TimeLineHeading,Img } from './styles/TimeLineBar.styled';
const Roadmap = () => {
  return (
  <TimeLineContainer>
  <TimeLineWrapper>
  <TimeLineHeading>OBI Roadmap</TimeLineHeading>
  </TimeLineWrapper>
  <div>
  <VerticalTimeline>
         <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: '#f0ebe9', color: '#262626' }}
            dateClassName={'vertical-timeline-element-date-roadmap'}
            icon={<Img src={require('./../images/Roadmap/5.png')} alt="myLogo" />} 
            iconStyle={{ background: '#d86148', color: '#fff' }}
          >
        <h3 className="vertical-timeline-element-title">Etherscan.io - Contract Showcase </h3>
        <h4 className="vertical-timeline-element-subtitle">date : closed</h4>
        <p>
        Release the OBI contracts to the public, pre-mint, so everyone can verify them and understand how our code is working.
        </p>
  </VerticalTimelineElement>

       
  <VerticalTimelineElement
          className="vertical-timeline-element--work"
          
          contentStyle={{ background: '#f0ebe9', color: '#262626' }}
          iconStyle={{ background: '#d86148', color: '#fff' }}
          icon={<Img src={require('./../images/Roadmap/6.png')} alt="myLogo" />} 
           >
          <h3 className="vertical-timeline-element-title">OBI list presale day</h3>
          <h4 className="vertical-timeline-element-subtitle">price: Free Mint </h4>
          <h4 className="vertical-timeline-element-subtitle">date : closed</h4>
          <p>
           A maximum number of 4 OBI NFTs will be available to mint depending on your White List allocation.
          </p>
           <p>
           Our gas optimized contract coupled with the possibility to mint anytime you want during a 24-hour window, will ensure you will pay the least amount of gas for your transactions. 
          </p>
          
    </VerticalTimelineElement>
    <VerticalTimelineElement
          className="vertical-timeline-element--work"
          
          contentStyle={{ background: '#f0ebe9', color: '#262626' }}
          iconStyle={{ background: '#d86148', color: '#fff' }}
          icon={<Img src={require('./../images/Roadmap/6.png')} alt="myLogo" />} 
        >
          <h3 className="vertical-timeline-element-title">OBI public mint day</h3>
          <h4 className="vertical-timeline-element-subtitle">price: Free Mint </h4>
          <h4 className="vertical-timeline-element-subtitle">date: closed </h4>
          <p>
             1 OBI NFT per wallet will be available for the public mint.
          </p>
          <p>
           OBI NFT will contain 1 skin and 1 color palette plus voting rights on future decision regarding the collection's direction. Each OBI NFT will also be able to mint future skins and palettes that will unlock extra utilities.
          </p>
    </VerticalTimelineElement>
    <VerticalTimelineElement
          className="vertical-timeline-element--work"
          
          contentStyle={{ background: '#f0ebe9', color: '#262626' }}
          iconStyle={{ background: '#d86148', color: '#fff' }}
          icon={<Img src={require('./../images/Roadmap/9.png')} alt="myLogo" />} 
        >
          <h3 className="vertical-timeline-element-title">The Light vs Dark side | The Unravel</h3>
          <h4 className="vertical-timeline-element-subtitle">price: Free Mint </h4>
          <h4 className="vertical-timeline-element-subtitle">date: closed</h4>
          <p>
          Mint the 2nd color palette | The Dark Side.
          </p>
          <p>
          This will change the color of your OBI to the Dark Side on OpenSea or any other similar platform. Note: You can switch back any time, for a small gas fee and the entire process will be done on-chain.
          </p>
    </VerticalTimelineElement>
   
    <VerticalTimelineElement
          className="vertical-timeline-element--work"
          
          contentStyle={{ background: '#f0ebe9', color: '#262626' }}
          iconStyle={{ background: '#d86148', color: '#fff' }}
          icon={<Img src={require('./../images/Roadmap/9.png')} alt="myLogo" />} 
        >
          <h3 className="vertical-timeline-element-title">The Light vs Dark side | The New Faction</h3>
          <h4 className="vertical-timeline-element-subtitle">date: TBA </h4>
          <p>
             Mint the 2nd skin | New Faction.
          </p>
           <p>
             Looking towards future possibilities, it is possible that a new and intriguing faction may emerge in the OBIverse. 
             Furthermore, there may be an opportunity to introduce a second OBI skin which would offer the flexibility to be mixed and matched with any color palette your OBI possess.
          </p>
     </VerticalTimelineElement>
     <VerticalTimelineElement
          className="vertical-timeline-element--work"
          
          contentStyle={{ background: '#f0ebe9', color: '#262626' }}
          iconStyle={{ background: '#d86148', color: '#fff' }}
          icon={<Img src={require('./../images/Roadmap/10.png')} alt="myLogo" />} 
        >
          <h3 className="vertical-timeline-element-title">OBI game</h3>
          <p>
          <p>
           As a potential future possibility, the OBI game may offer a retro-inspired experience where players can compete against one another for a chance to win .
          </p>
          </p>
           <p>
           There may come a time when the game itself will integrate with your NFTs, skins, and palettes, providing a delightful and casual experience where you can race against other players. 
           This future possibility may offer a seamless and enjoyable way to engage with your digital assets while enjoying the thrill of friendly competition
          </p>
        
     </VerticalTimelineElement>
    
     <VerticalTimelineElement
          className="vertical-timeline-element--work"
          
          contentStyle={{ background: '#f0ebe9', color: '#262626' }}
          iconStyle={{ background: '#d86148', color: '#fff' }}
          icon={<Img src={require('./../images/Roadmap/8.png')} alt="myLogo" />} 
        >
          <h3 className="vertical-timeline-element-title">Modular contract system </h3>
          
          <p>
          As we look ahead to the future, we anticipate that our modular design will support the implementation of future contracts, 
          opening up new possibilities for rendering on-chain and introducing fresh mechanics and optimizations. 

          </p>
        
     </VerticalTimelineElement>
       
        
        
    </VerticalTimeline>
    </div>
    </TimeLineContainer>
  )
}

export default Roadmap