import styled from "styled-components"

export const TimeLineContainer = styled.div`
padding: 160 px 0;
background: #242424;
`
export const Img = styled.img`
padding-right:0;
border:0 ;
max-width: 100%;
vertical-align: middle;
display: inline-block;
max-height:220px;
padding-bottom:0;

`
export const TimeLineWrapper = styled.div`
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  @media screen and (max-width: 960px) {
    margin: 0 30px;
    display: flex;
    flex-direction: column;
    align-items:left;
  }
`;
export const TimeLineHeading = styled.h1`
  color: #f0ebe9;
  font-size: 28px;
  margin-bottom: 14px;
`;