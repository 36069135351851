import React from 'react';
import { Button } from './styles/GlobalStyle';
import { AiFillThunderbolt } from 'react-icons/ai';
import { GiCrystalBars } from 'react-icons/gi';
import { GiCutDiamond, GiRock } from 'react-icons/gi';
import { GiFloatingCrystal } from 'react-icons/gi';
import { IconContext } from 'react-icons/lib';
import {
  TeamSection,
  TeamSection2,
  TeamWrapper,
  TeamHeading,
  TeamContainer,
  TeamCard,
  TeamCard2,
  TeamCardInfo,
  TeamCardInfo2,
  TeamCardIcon,
  TeamCardRole,
  TeamCardMemberName,
  TeamCardDescription,
  Img
  
} from './styles/Team.styled';

function Team() {
  return (
    <IconContext.Provider value={{ color: 'red', size: 64 }}>
      <TeamSection>
        <TeamWrapper>
          <TeamHeading>The OBI Team</TeamHeading>
          <TeamContainer>
            <TeamCard  href="https://twitter.com/A_R_T_IT">
              <TeamCardInfo>
                <TeamCardIcon>
                  <Img src={require('./../images/Team/cati.png')} alt="{alt}"/>
                </TeamCardIcon>
                
                <TeamCardMemberName>@A_R_T_IT</TeamCardMemberName>
                <TeamCardRole>art concept/ graphic design/ lore</TeamCardRole>
                <TeamCardDescription>
                  Multidisciplinary artist, designer, and writer.
                </TeamCardDescription>
                <TeamCardDescription>
                  The OBIs are a Journey that we start and continue Together. They are designed with Love. They are designed to connect us. 
                </TeamCardDescription>
                <TeamCardDescription>
                  More than anything else, OBI is YOU.
                </TeamCardDescription>
              </TeamCardInfo>
            </TeamCard>
            <TeamCard href="https://twitter.com/hennny_v">
              <TeamCardInfo>
                <TeamCardIcon>
                 <Img src={require('./../images/Team/heni.png')} alt="{alt}"/>
                </TeamCardIcon>
                <TeamCardMemberName>@Heny_V</TeamCardMemberName>
                <TeamCardRole>art concept/ graphic design</TeamCardRole>
                <TeamCardDescription>
                Architect and graphical designer, working on the art concept and graphical language for OBI.
                </TeamCardDescription>
                <TeamCardDescription>
                As a supporter of equal rights I have been amazed by how the NFT comunity has encouraged all artists to take part in this movement. I am very excited of building OBI as an inclusive collection, one where everyone can feel welcomed.
                </TeamCardDescription>
              
              </TeamCardInfo>
            </TeamCard>
            
           
          </TeamContainer>
        </TeamWrapper>
      </TeamSection>

      <TeamSection2>
        <TeamWrapper>
          <TeamContainer>
            <TeamCard2 href="https://twitter.com/Ugly2PointO">
              <TeamCardInfo2>
                <TeamCardIcon>
                <Img src={require('./../images/Team/radu.png')} alt="{alt}"/>
                </TeamCardIcon>
                <TeamCardMemberName>@Ugly 2.0</TeamCardMemberName>
                <TeamCardRole>co-founder/ bussiness development</TeamCardRole>
                <TeamCardDescription>
                  Architect and designer IRL, NFT collector and co-founder of Onchain Block Invaders. 
                </TeamCardDescription>
                <TeamCardDescription>
                I believe that we are witnessing a true tech revolution with web 3.0 that will have an impact on all of our day to day lifes. My mission is to make OBI part of this movement together with the team and promote true onchain art and ownership.
                </TeamCardDescription>
                
              </TeamCardInfo2>
            </TeamCard2>

            <TeamCard2 href="https://twitter.com/vv333d">
              <TeamCardInfo2>
                <TeamCardIcon>
                <Img src={require('./../images/Team/vali.png')} alt="{alt}"/>
                </TeamCardIcon>
                <TeamCardMemberName>@vv333d</TeamCardMemberName>
                <TeamCardRole>co-founder/ dev</TeamCardRole>
                <TeamCardDescription>
                 Multidisciplinary software developer with more than 17 years of experience in payments and automotive industries. 
                </TeamCardDescription>
                <TeamCardDescription>
                 The combination of my experience and my passion for blockchain technology has allowed me to build a solid foundation for OBI.
                </TeamCardDescription>
              </TeamCardInfo2>
            </TeamCard2>
            
           
          </TeamContainer>
        </TeamWrapper>
      </TeamSection2> 


    </IconContext.Provider>
  );
}

export default Team;