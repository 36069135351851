import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ExternalLink } from 'react-external-link';

export const TeamSection = styled.div`
  padding: 1px 0 1px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #f0ebe9;
`;

export const TeamSection2 = styled.div`
  padding: 1px 0 1px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #242424;
`;

export const TeamWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  @media screen and (max-width: 960px) {
    margin: 0 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const TeamHeading = styled.h1`
  color: #242424;
  font-size: 28px;
  margin-bottom: 14px;
`;

export const TeamContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  @media screen and (max-width: 960px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
`;

export const TeamCard = styled(ExternalLink)`
  background: #242424;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.2);
  width: 500px;
  height: 400px;
  text-decoration: none;
  border-radius: 4px;
  &:nth-child(2) {
    margin: 24px;
  }
  &:hover {
    transform: scale(1.02);
    transition: all 0.3s ease-out;
    color: #1c2237;
  }
  @media screen and (max-width: 960px) {
    width: 90%;
    &:hover {
      transform: none;
    }
  }
`;
export const TeamCard2 = styled(ExternalLink)`
  background: #f0ebe9;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.2);
  width: 500px;
  height: 400px;
  text-decoration: none;
  border-radius: 4px;
  &:nth-child(2) {
    margin: 24px;
  }
  &:hover {
    transform: scale(1.02);
    transition: all 0.3s ease-out;
    color: #1c2237;
  }
  @media screen and (max-width: 960px) {
    width: 90%;
    &:hover {
      transform: none;
    }
  }
`;


export const TeamCardInfo = styled.div`
  display: flex;
  flex-direction: column;
  height: 400px;
  padding: 16px;
  align-items: center;
  color: #f0ebe9;
`;

export const TeamCardInfo2 = styled.div`
  display: flex;
  flex-direction: column;
  height: 400px;
  padding: 16px;
  align-items: center;
  color: #242424;
`;

export const TeamCardIcon = styled.div`
  margin: 0px 0;
`;

export const Img = styled.img`
padding-right:0;
border:0 ;
max-width: 100%;
vertical-align: middle;
display: inline-block;
max-height:80px;
padding-bottom:0;

`

export const TeamCardRole = styled.h3`
  margin-bottom: 10px;
  font-size: 15px;
`;

export const TeamCardMemberName = styled.h4`
  font-size: 28px;
  margin-bottom: 5px;
`;

export const TeamCardDescription = styled.p`
  text-indent: 10px;
  font-size: 14px;
  margin-bottom: 0px;
  text-align: justify;
`;

export const TeamCardFeatures = styled.ul`
  margin: 16px 0 32px;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #a9b3c1;
`;

export const TeamCardFeature = styled.li`
  margin-bottom: 10px;
`;