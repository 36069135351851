import React from 'react'
//import { Button } from  './styles/GlobalStyle'

import { ExternalLink } from 'react-external-link';

import { FooterContainer,
        FooterSubscription,
        FooterSubHeading,
        FooterSubText,
        FooterLink,
        Link,
        Form,
        FormInput
  } from './styles/Footer.styled';
  
const Footer = () => {
  return (
    <FooterContainer>
        <FooterSubscription>
            <FooterSubHeading>
             Created by the OBI Team with special ♥ to our OBI friends. 
            </FooterSubHeading>
        </FooterSubscription>
    </FooterContainer>
  )
}

export default Footer
