//rafce
import React,{useEffect, useState} from 'react';
import { Nav,
        NavbarContainer,
        NavLogo,
        NavIcon,
        MobileIcon,
        NavMenu,
        NavItem,
        NavLink,
        Img,
        SocialLink,
        NavMoto,
        NavItemBtn, 
        NavBtnLink
        } from './styles/NavBar.styled';
import { FaTimes ,FaBars} from "react-icons/fa";
import { IconContext } from 'react-icons/lib';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBehance,
  faDiscord,
  faTwitter,
  faInstagram
} from "@fortawesome/free-brands-svg-icons";
//To Do add connect button
/*import { Button } from './styles/GlobalStyle';

<NavItemBtn>
                
            {button ? (
                <NavBtnLink to="/connect">
                    <Button primary>Connect Wallet</Button>
                </NavBtnLink>    
            ):(
                <NavBtnLink to="/connect">
                    <Button fontBig primary>Connect Wallet</Button>
                </NavBtnLink>  
             ) }
            </NavItemBtn>*/

const NavBar = () => {
  const [click,setClick] = useState(false);
  const [button,setButton] = useState(true);
  const handleClick = () => setClick(!click);
  const showButton = () => 
  {
    if (window.innerWidth <= 960) {
    setButton(false);
    }
    else {
    setButton(true);
    }
}
useEffect(() => {
    showButton();
}, []);
window.addEventListener('resize', showButton);
  return (
    <>
     <IconContext.Provider value={{ color: "black"}}>
      <Nav>
        <NavbarContainer>
          <NavLogo to="/">
          <Img src={require('./../images/logo.png')} alt="{alt}"/>
           OBI
           <NavMoto>bold-simple-inclusive</NavMoto> 
          </NavLogo>   
          <MobileIcon onClick={handleClick} >
            {click ? <FaTimes  /> :<FaBars />}
          </MobileIcon>
          <NavMenu onClick={handleClick} click={click}>
            
            <NavItem>
                <NavLink to="/">Home</NavLink>
            </NavItem>
            <NavItem>
                <NavLink to="/roadmap">Roadmap</NavLink>
            </NavItem>
            <NavItem>
                <NavLink to="/lore">Lore</NavLink>
            </NavItem>
            <NavItem>
                <NavLink to="/team">Team</NavLink>
            </NavItem>

            <NavItem>
                <SocialLink href="https://discord.gg/NDzsuqaUGH">
                <FontAwesomeIcon icon={faDiscord} size="1x" />
                  </SocialLink>
            </NavItem>
            <NavItem>
                <SocialLink href="https://twitter.com/OcBlockInvaders">
                <FontAwesomeIcon icon={faTwitter} size="1x" />
                  </SocialLink>
            </NavItem>
            <NavItem>
                <SocialLink href="https://www.behance.net/gallery/137537259/Booklet">
                <FontAwesomeIcon icon={faBehance} size="1x" />
                  </SocialLink>
            </NavItem>
            
            
           </NavMenu>
        </NavbarContainer>    
      </Nav>
    </IconContext.Provider>
    </>
  );
};

export default NavBar;

