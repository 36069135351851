import styled from "styled-components"

export const LoreSec = styled.div`
color: #fff;
padding: 20 px 0;
background: ${({ lightBg }) => (lightBg ? '#f0ebe9' : '#262626')};

`
export const LoreRow = styled.div`
display: flex;
margin:0 -15px -15px -15px;
flex-wrap: wrap;
align-items: center;
flex-direction: ${({ imgStart }) => (imgStart ? 'row-reverse' : 'row')};

`
export const LoreColumn = styled.div`
margin-bottom: 15px;
padding-right: 15px;
padding-left: 15px;
flex: 1;
max-width: 100%;
flex-basis: 100%;


@media screen and (max-width: 1420px) {
max-width: 100%;
flex-basis: 100%;
display: flex;
justify-content: center;
}
`

export const LoreColumnH = styled.div`
margin-bottom: 15px;
padding-right: 15px;
padding-left: 15px;
flex: 1;
max-width: 50%;
flex-basis: 50%;

@media screen and (max-width: 868px) {
max-width: 100%;
flex-basis: 100%;
display: flex;
justify-content: center;
}
`

export const TextWrapper = styled.div`
max-width : 1420px;
padding-top: 0px;
padding-bottom:10px;

@media screen and (max-width: 868px) {
padding-bottom: 10px;
}
`

export const TextWrapperH = styled.div`
max-width : 540px;
padding-top: 20px;
padding-bottom:0px;


@media screen and (max-width: 768px) {
padding-bottom: 10px;
}
`


export const TopLine = styled.div`
color: ${({ lightTopLine }) => (lightTopLine ? '#d86148' : '#d86148')};
font-size:18px;
line-height:16px;
letter-spacing:1.4px;
margin-bottom:16px;
`
export const Heading = styled.div`
margin-bottom:24px;
font-size:48px;
line-height:1.1;
font-weight: 600;
color: ${({ lightText }) => (lightText ? '#F7F8FA' : '#1C2237')};
`
export const Subtitle = styled.p`
max-width: 880px;
margin-bottom: 15px;
font-size: 17px;
line-height: 24px;
text-indent: 10px;
text-align: justify;
color: ${({ lightTextDesc }) => (lightTextDesc ? '#A9B3C1' : '#1c2237')};
`
export const SubtitleH = styled.p`
max-width: 440px;
margin-bottom: 15px;
font-size: 17px;
line-height: 24px;
text-indent: 10px;
color: ${({ lightTextDesc }) => (lightTextDesc ? '#A9B3C1' : '#1c2237')};
`
export const ImgWrapper = styled.div`
max-width: 555px;
display: flex;
justify-content: ${({ start }) => (start ? 'flex-start' : 'flex-end')};
`
export const Img = styled.img`
padding-right: 0;
border: 0;
max-width: 100%;
vertical-align: middle;
display: inline-block;
max-width:350px;

`