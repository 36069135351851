import styled from 'styled-components';
import { ExternalLink } from 'react-external-link';

export const FooterContainer = styled.div`
background-color: #f0ebe9;
padding: 1rem 0.5rem 0;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;

`

export const FooterSubscription = styled.section`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
text-align: center;
margin-bottom: 5px;
padding: 5px;
color: #242424;

`
export const Link = styled.a`
  font-family: sans-serif;
  background: blue;
  color: white;
`;

export const FooterSubHeading = styled.div`

margin-bottom: 10px;
font-size: 18px;

`

export const FooterSubText = styled.p`
margin-bottom: 22px;
font-size: 20px;

`
export const Form = styled.form`
display: flex;
justify-content: center;
align-items: center;
@media screen and (max-width: 820px) {
    flex-direction: column;
    width: 80%;
}

`
export const FormInput = styled.input`
padding:10px 20px;
border-radius: 5px;
margin-right: 10px;
outline: none;
border: none;
font-size:16px ;
border:1px solid #fff;
`
export const FooterLink = styled(ExternalLink)`
  text-decoration: none;
  color: #d86148;
  &:hover {
    transform: scale(1.02);
    transition: all 0.3s ease-out;
    color: #d86148;
  }
  @media screen and (max-width: 960px) {
    width: 90%;
    &:hover {
      transform: none;
    }
  }
`;