import React from 'react'
import InfoSection from './InfoSection'
import InfoSectionVideo from './InfoSectionVideo'
import { HomeObj1,
         HomeObj2,
         HomeObj3,
         HomeObj4,
         HomeObj5,
         HomeObj6,
         HomeObj7
       } from './data'


const Home = () => {
  return (
    <>
     
     <InfoSectionVideo { ...HomeObj1 } />
     <InfoSection { ...HomeObj2 } />
     <InfoSection { ...HomeObj3 } />
     <InfoSection { ...HomeObj4 } />
     <InfoSection { ...HomeObj5 } />
     <InfoSection { ...HomeObj6 } />
     <InfoSection { ...HomeObj7 } />
     
    </>
  )
}

export default Home;