import styled,{ createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600;700;800&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
}
`
export const Container=styled.div`
z-index: 1;
width: 100%;
max-width: 1300px;
margin-right: auto;
margin-left: auto;
padding-right: 50px;
padding-left: 50px;
@media (max-width:991px){
  padding-right: 30px;
  padding-left: 30px;
};
`
export const Button=styled.button`
border-radius: 5px;
background:${({primary})=>(primary?'#4B59F7':'#0467FB')}; 
white-space: nowrap;
padding:${({big})=>(big?'12px 64':'10px 20px')}; 
color: #fff;
font-size: ${({fontBig})=>(fontBig?'20px':'16px')};
outline: none;
border: none;
cursor: pointer;
&:hover{
  transition: all 0.3s ease-in-out;
  background:#fff;
  background:${({primary})=>(primary?'#0467FB':'#4B59F7')};
  @media screen and (max-width:991px){
  width: 100%;
  }
  } 
`

export default GlobalStyle;