import {React}  from 'react';
import {BrowserRouter as Router ,Routes,Route } from 'react-router-dom';
import NavBar from './components/NavBar';
import GlobalStyle from './components/styles/GlobalStyle';
import Home from './components/Home';
import Lore from './components/Lore';
import Team from './components/Team';

import Roadmap from './components/Roadmap';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';
import {Helmet} from "react-helmet";

function App() {  
    return (      
      <>
      <GlobalStyle />
       <NavBar />
       <ScrollToTop />
        <Routes>
          <Route exact path="/" element={<Home/>} />
          <Route exact path="/roadmap" element={<Roadmap/>} />
          <Route exact path="/lore" element={<Lore/>} />
          <Route exact path="/team" element={<Team/>} />
        </Routes>
        <Footer />
       </>
    );
  
}
 
export default App;